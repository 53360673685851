var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-goal"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"main-goal__blocks"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.className,class:`main-goal__block main-goal__block--${block.className}`},[_c('div',{staticClass:"main-goal__block_content"},[_c('h3',{domProps:{"innerHTML":_vm._s(block.title)}}),_vm._v(" "),_c('div',{staticClass:"main-goal__block_cheap"},[_vm._l((block.cheap),function(cheap,index){return [(cheap.to)?_c('nuxt-link',{key:index,class:{
                hovered: cheap.hovered,
                'hide-mobile': cheap.hideMobile,
                'hide-desktop': cheap.hideDesktop,
              },attrs:{"to":cheap.to}},[_c('b',{domProps:{"innerHTML":_vm._s(cheap.text)}}),_vm._v(" "),_c('img',{attrs:{"src":"/v2/main/programs/arrow.svg","alt":"arrow","loading":"lazy"}}),_vm._v(" "),(cheap.sup)?_c('span',{key:`sup-${cheap.sup}`,staticClass:"main-goal__block_cheap_sup"},[_vm._v("\n                "+_vm._s(cheap.sup)+"\n              ")]):_vm._e()]):_vm._e(),_vm._v(" "),(cheap.modal)?_c('button',{key:index,class:['m-btn', {
                hovered: cheap.hovered,
                'hide-mobile': cheap.hideMobile,
                'hide-desktop': cheap.hideDesktop,
              }],on:{"click":function($event){return _vm.openModal(cheap.modal)}}},[_c('b',{domProps:{"innerHTML":_vm._s(cheap.text)}}),_vm._v(" "),_c('img',{attrs:{"src":"/v2/main/programs/arrow.svg","alt":"arrow","loading":"lazy"}}),_vm._v(" "),(cheap.sup)?_c('span',{key:`sup-${cheap.sup}`,staticClass:"main-goal__block_cheap_sup"},[_vm._v("\n                "+_vm._s(cheap.sup)+"\n              ")]):_vm._e()]):_vm._e()]})],2)]),_vm._v(" "),(block.videos[block.currentVideoIndex])?_c('main-goal-video',{staticClass:"main-goal__block_video",attrs:{"videos-count":block.videos.length,"video":block.videos[block.currentVideoIndex],"video-number":block.currentVideoIndex + 1},on:{"next":function($event){return _vm.nextVideo(block)},"play":function($event){block.currentVideoIndex = $event - 1}}}):_vm._e()],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('h2',[_c('span',[_vm._v("поможем вам и ребенку")]),_vm._v(" достичь целей")])])
}]

export { render, staticRenderFns }